import React from 'react';
import TilePage from '../../TilePage';

const Tile1 = () => {
  return (
    <TilePage />
  );
};

export default Tile1;
